import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import imageIndex from '../ImageIndex';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const whiteLogos = [
  { src: imageIndex.AcademiaCocytenbLogo, link: '', name: '' },
  { src: imageIndex.AcademiaCUTLogo, link: '', name: '' },
  { src: imageIndex.AcademiaSharevoltsLogo, link: '', name: '' },
  { src: imageIndex.AcademiaMuseoInteractivoLogo, link: '', name: '' },
  { src: imageIndex.AcademiaUCEMICHLogo, link: '', name: '' },
  { src: imageIndex.AcademiaUniversidadTecMilenioLogo, link: '', name: '' },
  { src: imageIndex.AcademiaVikingosLogo, link: '', name: '' },
];

const logoStyle = {
  width: '150px',
  height: 'auto',
  margin: '0 64px',
  paddingBottom: '40px',
  opacity: 1,
  transition: 'transform 0.3s, opacity 0.3s',
};

const hoverStyle = {
  transform: 'scale(1.8)',
  opacity: 1,
};

const extraStyle = {
  width: '300px',
};

export default function CampusChiefCollaboratorsLogos() {
  const logos = whiteLogos;
  const repeatTimes = 1;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 5, sm: 5 },
          pb: { xs: 5, sm: 5 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            Nuestros&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'rgb(239, 80, 33)',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              colaboradores
            </Typography>
          </Typography>
        </Stack>
      </Container>
      {[...Array(repeatTimes)].map((_, repeatIndex) => (
        <Grid container key={repeatIndex} sx={{ justifyContent: 'center', alignItems: 'center', mt: 0.5, opacity: 0.6 }}>
          {logos.map((logo, index) => {
            const isSpecialLogo = logo.name.includes('special'); // Replace 'special-logo-name' with the actual name

            return (
              <Grid item key={index}>
                <a href={logo.link} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>
                  <img
                    src={logo.src}
                    alt={`Logo ${index + 1}`}
                    style={{ ...logoStyle, ...(isSpecialLogo ? extraStyle : {}) }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = hoverStyle.transform;
                      e.currentTarget.style.opacity = hoverStyle.opacity;
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = '';
                      e.currentTarget.style.opacity = logoStyle.opacity;
                    }}
                  />
                </a>
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Box>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import imageIndex from '../ImageIndex';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageCarousel = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const isMobile = () => window.innerWidth <= 768;

  const adjustH1StylesForMobile = () => {
    if (isMobile()) {
      document.querySelectorAll('.css-1j9ggd5-MuiTypography-root').forEach(h1 => {
        h1.style.fontSize = '20px'; // Adjust font size for mobile
        // Apply any other mobile-specific styles here
      });
    }
  };

  adjustH1StylesForMobile();

  return (
    <Container sx={{ width: { xs: '100%', sm: '70%' }, margin: '0 auto' }}>
      <Slider {...settings}>
        <div style={{ ...carouselImageStyle, borderRadius: '12px', overflow: 'hidden' }}>
          <img src={imageIndex.ENMICE202115} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
        </div>
        <div style={{ ...carouselImageStyle, borderRadius: '12px', overflow: 'hidden' }}>
          <img src={imageIndex.ENMICE202106} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
        </div>
        <div style={{ ...carouselImageStyle, borderRadius: '12px', overflow: 'hidden' }}>
          <img src={imageIndex.ENMICE202105} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
        </div>
      </Slider>
    </Container>
    );
    };

  const carouselImageStyle = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative',
    borderRadius: '25px',
  };

export default function ENMICE2022Features() {

  return (
    <Container id="features" sx={{ py: { xs: 0, sm: 0 }, pt: { xs: 0, sm: 0 } }}>
      <Box>
        <ImageCarousel />
      </Box>
    </Container>
    
  );
}

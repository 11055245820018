import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import imageIndex from '../ImageIndex';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import { styled } from '@mui/material/styles';

/*const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '1px solid',
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${'https://enmicemedia.blob.core.windows.net/media/Página web ENMICE 2024-2025/Audiovisual para contenido/Academia ENMICE/IMG_9231.JPG'})`,
  outlineColor: 'hsla(220, 25%, 80%, 0.5)',
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${'/static/images/templates/templates-images/hero-dark.png'})`,
    outlineColor: 'hsla(210, 100%, 80%, 0.1)',
  }),
}));*/

const ImageCarousel = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const carouselImageStyle = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative'
  };

  {/*const baseOverlayTextStyle = {
    position: 'absolute',
    top: '70%',
    backgroundColor: 'hsla(220, 25%, 10%, 0.9)',
    borderRadius: '12px',
    padding: '20px',
    fontSize: '40px',
    margin: 10
  };*/}

  {/*const mobileOverlayTextStyle = {
    fontSize: '10px', // Smaller font size for mobile
    padding: '20px', // Less padding for mobile
    top: '40%' // Adjust position for mobile
  };*/}

  const isMobile = () => window.innerWidth <= 768;

{/*const overlayTextStyle = isMobile() ? { ...baseOverlayTextStyle, ...mobileOverlayTextStyle } : baseOverlayTextStyle;*/}

  const adjustH1StylesForMobile = () => {
    if (isMobile()) {
      document.querySelectorAll('.css-1j9ggd5-MuiTypography-root').forEach(h1 => {
        h1.style.fontSize = '20px'; // Adjust font size for mobile
        // Apply any other mobile-specific styles here
      });
    }
  };

  // Call this function when the component mounts or when the window resizes
  adjustH1StylesForMobile();

return (
  <Slider {...settings}>
    <div style={{ ...carouselImageStyle, borderRadius: '12px', overflow: 'hidden' }}>
      <img src={imageIndex.AcademiaCarousel2} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      {/*<div style={overlayTextStyle}>
        <h1 class="MuiTypography-root MuiTypography-h1 css-1j9ggd5-MuiTypography-root">El evento y compentecia líder</h1>
        <h1 class="MuiTypography-root MuiTypography-h1 css-iax7i7-MuiTypography-root">De cohetería experimental en México</h1>
        <button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-1wdir3v-MuiButtonBase-root-MuiButton-root">Convocatoria 2023</button>
      </div>*/}
    </div>
    <div style={{ ...carouselImageStyle, borderRadius: '12px', overflow: 'hidden' }}>
      <img src={imageIndex.AcademiaCarousel1} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      {/*<div style={overlayTextStyle}>
        <h1 class="MuiTypography-root MuiTypography-h1 css-1j9ggd5-MuiTypography-root">El evento y compentecia líder</h1>
        <h1 class="MuiTypography-root MuiTypography-h1 css-iax7i7-MuiTypography-root">De cohetería experimental en México</h1>
        <button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-1wdir3v-MuiButtonBase-root-MuiButton-root">Convocatoria 2023</button>
      </div>*/}
    </div>
    <div style={{ ...carouselImageStyle, borderRadius: '12px', overflow: 'hidden' }}>
      <img src={imageIndex.AcademiaCarousel3} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      {/*<div style={overlayTextStyle}>
        <h1 class="MuiTypography-root MuiTypography-h1 css-1j9ggd5-MuiTypography-root">El evento y compentecia líder</h1>
        <h1 class="MuiTypography-root MuiTypography-h1 css-iax7i7-MuiTypography-root">De cohetería experimental en México</h1>
        <button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-1wdir3v-MuiButtonBase-root-MuiButton-root">Convocatoria 2023</button>
      </div>*/}
    </div>
  </Slider>
);
};

export default function AcademiaHero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 10, sm: 15 },
          pb: { xs: 8, sm: 10 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Box
            component="img"
            src={imageIndex.AcademiaENMICELogo}
            alt="Academia ENMICE"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              width: '50%', // Adjust as needed
              height: 'auto', // Adjust as needed
              maxWidth: '100%', // Ensure the image is responsive
            }}
          />
        </Stack>
        {/*<StyledBox id="image" />*/}
      </Container>
      <ImageCarousel />
    </Box>
  );
}

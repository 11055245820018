//Home
import HomeCarousel1 from './assets/Audiovisual para contenido/ENMICE carrusel principal.jpg';
import HomeCarousel2 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-2.jpg';
import HomeCarousel3 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-3.jpg';
import HomeCarousel4 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-4.jpg';
import HomeCarousel5 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-5.jpg';
import HomeCarousel6 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-6.jpg';
import HomeCarousel7 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-7.jpg';
import HomeCarousel8 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-8.jpg';
import HomeCarousel9 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-9.jpg';
import HomeCarousel10 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-10.jpg';
import ENMICELogo from './assets/Logotipos/ENMICE/Imagotipo ENMICE circular BC.png';
import ENMICE2021Map from './assets/ENMICE desarrollo coheteria nivel nacional-2021.png';
import ENMICE2022Map from './assets/ENMICE desarrollo coheteria nivel nacional-2022.png';
import ENMICE20232024Map from './assets/ENMICE desarrollo coheteria nivel nacional-2023 2024.png';

//Staff Images
import AboutUsFelipeAvilaImage from './assets/Fotos de equipo ENMICE/Staff/Felipe Ávila.jpg';
import AboutUsAngelVazquezImage from './assets/Fotos de equipo ENMICE/Staff/Foto Angel Velazquez.jpg';
import AboutUsArielGomezImage from './assets/Fotos de equipo ENMICE/Staff/Foto Ariel Gomez.jpg';
import AboutUsMaiteCUImage from './assets/Fotos de equipo ENMICE/Staff/Foto Maité CU.jpg';
import AboutUsMalcomPinedaImage from './assets/Fotos de equipo ENMICE/Staff/Foto Malcom Pineda.jpg';
import AboutUsPaulinaAguayoImage from './assets/Fotos de equipo ENMICE/Staff/Foto Paulina Aguayo.jpg';
import AboutUsRamonCordovaImage from './assets/Fotos de equipo ENMICE/Staff/Foto Ramon Córdova.jpg';
import AboutUsRodrigoMorenoImage from './assets/Fotos de equipo ENMICE/Staff/Foto Rodrigo Moreno.jpg';
import AboutUsRossyDelgadoImage from './assets/Fotos de equipo ENMICE/Staff/Foto Rossy Delgado Ortiz.jpg';
import AboutUsXimenaVelascoImage from './assets/Fotos de equipo ENMICE/Staff/Foto Ximena Velasco.jpg';
import AboutUsPatyLomeliImage from './assets/Fotos de equipo ENMICE/Staff/Foto Paty Lomelí.jpg';
import AboutUsPaulinaCabreraImage from './assets/Fotos de equipo ENMICE/Staff/Foto Paulina Cabrera.jpg';

//Campus Chief Images
import AboutUsGabrielBasilioImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Gabriel Basilio.jpg';
import AboutUsJoseAndresImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto José Andrés.jpg';
import AboutUsKarlaDiazImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Karla Díaz.jpg';
import AboutUsLeonelImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Leonel.jpg';
import AboutUsMariaFernandaImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto María Fernanda.jpg';
import AboutUsMtroJesusImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Mtro Jesus.jpg';
import AboutUsValeriaCurielImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Valeria Curiel.jpg';

//ENMICE 2021
import ENMICE202101 from './assets/Audiovisual para contenido/ENMICE 2021/20210529_100605.jpg';
import ENMICE202102 from './assets/Audiovisual para contenido/ENMICE 2021/20210529_112235_2.jpg';
import ENMICE202103 from './assets/Audiovisual para contenido/ENMICE 2021/20210530_135156_HDR_2.jpg';
import ENMICE202104 from './assets/Audiovisual para contenido/ENMICE 2021/20210530_142450_3.jpg';
import ENMICE202105 from './assets/Audiovisual para contenido/ENMICE 2021/DSCN3115.jpg';
import ENMICE202106 from './assets/Audiovisual para contenido/ENMICE 2021/DSCN3120.jpg';
import ENMICE202107 from './assets/Audiovisual para contenido/ENMICE 2021/ENMICE 2022 foto 10.jpg';
import ENMICE202108 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_0575.jpg';
import ENMICE202109 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_0938.jpg';
import ENMICE202110 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_1132.jpg';
import ENMICE202111 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_1815.jpg';
import ENMICE202112 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_4138.jpg';
import ENMICE202113 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_9865.jpg';
import ENMICE202114 from './assets/Audiovisual para contenido/ENMICE 2021/IMG-20210529-WA0043.jpg';
import ENMICE202115 from './assets/Audiovisual para contenido/ENMICE 2021/IMG-20210529-WA0074.jpg';
import ENMICE202116 from './assets/Audiovisual para contenido/ENMICE 2021/Screenshot 2021-06-01 111527.jpg';

//ENMICE 2022
import ENMICE202201 from './assets/Audiovisual para contenido/ENMICE 2022/1E36866A-7805-4B0A-89B3-2A1A4F38957E.jpg';
import ENMICE202202 from './assets/Audiovisual para contenido/ENMICE 2022/247F4347-8587-404A-A574-17585360BBEF.jpg';
import ENMICE202203 from './assets/Audiovisual para contenido/ENMICE 2022/Carrusel-ENMICE-2022_1.jpg';
import ENMICE202204 from './assets/Audiovisual para contenido/ENMICE 2022/Carrusel-ENMICE-2022_8.jpg';
import ENMICE202205 from './assets/Audiovisual para contenido/ENMICE 2022/Cohete kondakova.jpg';
import ENMICE202206 from './assets/Audiovisual para contenido/ENMICE 2022/DSC_6918.jpg';
import ENMICE202207 from './assets/Audiovisual para contenido/ENMICE 2022/DSC07222.ARW';
import ENMICE202208 from './assets/Audiovisual para contenido/ENMICE 2022/ENMICE 2022 foto 09.jpg';
import ENMICE202209 from './assets/Audiovisual para contenido/ENMICE 2022/ENMICE 2022 foto.jpg';
import ENMICE202210 from './assets/Audiovisual para contenido/ENMICE 2022/Foto general.jpg';
import ENMICE202211 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2312.JPG';
import ENMICE202212 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2444.HEIC';
import ENMICE202213 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2471.JPG';
import ENMICE202214 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2476.JPG';
import ENMICE202215 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2513 2.jpg';
import ENMICE202216 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_4020.jpeg';
import ENMICE202217 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_4021.jpeg';
import ENMICE202218 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_4038.jpeg';
import ENMICE202219 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7136.JPG';
import ENMICE202220 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7215.JPG';
import ENMICE202221 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7216.HEIC';
import ENMICE202222 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7254.HEIC';
import ENMICE202223 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7285.JPG';
import ENMICE202224 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7308.JPG';
import ENMICE202225 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7491.JPG';
import ENMICE202226 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7509.JPG';
import ENMICE202227 from './assets/Audiovisual para contenido/ENMICE 2022/IMG-20220926-WA0091.jpg';

//ENMICE 2023
import ENMICE202301 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_DSC1902.jpg';
import ENMICE202302 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_DSC6025.JPG';
import ENMICE202303 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_MG_4561.JPG';
import ENMICE202304 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_MG_4697.jpg';
import ENMICE202305 from './assets/Audiovisual para contenido/ENMICE 2023-2024/ENMICE 2023-2024.jpg';
import ENMICE202306 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0109.jpg';
import ENMICE202307 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0127.jpg';
import ENMICE202308 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0191.jpg';
import ENMICE202309 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0300.JPEG';
import ENMICE202310 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0354.jpg';
import ENMICE202311 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_7986.jpg';
import ENMICE202312 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_9999.jpg';
import ENMICE202313 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_20240408_143253.jpg';

//Academia
import AcademiaENMICELogo from './assets/Logotipos/ENMICE/Academia ENMICE logo_Mesa de trabajo 1.png';
import AcademiaENMICEOnePager from './assets/Academia ENMICE onepage 2024-2025.jpg';
import AcademiaCocytenbLogo from './assets/Logotipos/Academia ENMICE/COCYTEN-04.png';
import AcademiaSharevoltsLogo from './assets/Logotipos/Academia ENMICE/Logo-ShareVolts-01-1.png';
import AcademiaMuseoInteractivoLogo from './assets/Logotipos/Academia ENMICE/Museo Interactivo - Tinto vertical.png';
import AcademiaUCEMICHLogo from './assets/Logotipos/Academia ENMICE/UCEMICH Color logo.png';
import AcademiaUniversidadTecMilenioLogo from './assets/Logotipos/Academia ENMICE/Universidad Tec Milenio.png';
import AcademiaCUTLogo from './assets/Logotipos/Academia ENMICE/cut.png';
import AcademiaVikingosLogo from './assets/Logotipos/Academia ENMICE/Vikingos Espaciales color.png';
import AcademiaCarousel1 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-carrusel.jpg';
import AcademiaCarousel2 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-carrusel-2.jpg';
import AcademiaCarousel3 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-carrusel-3.jpg';
import AcademiaCarousel4 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-carrusel-4.jpg';
import AcademiaCarousel5 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-carrusel-5.jpg';
import Academia1 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_3611.HEIC';
import Academia2 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_7022.HEIC';
import Academia3 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_7025.HEIC';
import Academia4 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_7031.HEIC';
import Academia5 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_8904.HEIC';
import Academia6 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_8906.HEIC';
import Academia7 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_9230.JPG';
import Academia8 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_9231.JPG';
import Academia9 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_9233.JPG';

//Allies
//Sponsors
import SkarchLogo from './assets/Logotipos/Patrocinadores/Agua Skarch logo blanco.png';
import AnsysLogo from './assets/Logotipos/Patrocinadores/Ansys logo blanco.png';
import AXONLogo from './assets/Logotipos/Patrocinadores/AXON_cables-connectors logo blanco.png';
import AutoTokioNissanLogo from './assets/Logotipos/Patrocinadores/Auto Tokio Nissan logo blanco.png';
import Bid360Logo from './assets/Logotipos/Patrocinadores/Bid 360 logo blanco.png';
import CraftAviaLogo from './assets/Logotipos/Patrocinadores/CRAFT AVIA LOGO BLANCO.png';
import DaviLogo from './assets/Logotipos/Patrocinadores/Davi logo blanco.png';
import GrupoSSCLogo from './assets/Logotipos/Patrocinadores/Grupo SSC logo blanco.png';
import DewesoftLogo from './assets/Logotipos/Patrocinadores/Logo Dewesoft blanco.png';
import ERAAviacionLogo from './assets/Logotipos/Patrocinadores/Logo ERA Aviacion blanco.png';
import HoneywellAerospaceTechnologiesLogo from './assets/Logotipos/Patrocinadores/Logo Honeywell Aerospace Technologies blanco.png';
import NoMADALogo from './assets/Logotipos/Patrocinadores/Logo NoMADA blanco.png';
import SharevoltsLogo from './assets/Logotipos/Patrocinadores/Logo Sharevolts 2.png';
import MarDSalLogo from './assets/Logotipos/Patrocinadores/Mar d_ Sal logo blanco.png';
import RedColaLogo from './assets/Logotipos/Patrocinadores/Red Cola logo blanco.png';
import SidralAgaLogo from './assets/Logotipos/Patrocinadores/Sidral Aga logo balnco.png';
import SterenLogo from './assets/Logotipos/Patrocinadores/Steren Logotipo blanco.png';
import ThrustersUnlimitedLogo from './assets/Logotipos/Patrocinadores/Thrusters Unlimited blanco logo.png';
import UMGLogo from './assets/Logotipos/Patrocinadores/UMG LOGO WEB.png';

//Collaborators
import VivevolandoLogo from './assets/Logotipos/Colaboradores/Academia Aeronáutica Vivevolando logo blanco.png';
import AhChihuahuaLogo from './assets/Logotipos/Colaboradores/Ah-Chihuahua logo.png';
import BuroDeConvencionesChihuahuaLogo from './assets/Logotipos/Colaboradores/Buró de convenciones Chihuahua logo blanco.png';
import CamaraDeDiputadosLogo from './assets/Logotipos/Colaboradores/Cámara de diputados logo blanco.png';
import CenaltecLogo from './assets/Logotipos/Colaboradores/Cenaltec logo blanco.png';
import ChihuahuaCapitalLogo from './assets/Logotipos/Colaboradores/Chihuahua Capital Logo blanco.png';
import ChihuahuaAerospaceClusterLogo from './assets/Logotipos/Colaboradores/Chihuahua_s Aerospace Cluster logo blanco.png';
import CIMAVLogo from './assets/Logotipos/Colaboradores/CIMAV logo blanco.png';
import ConsejoAeroespacialDeJaliscoLogo from './assets/Logotipos/Colaboradores/Consejo Aeroespacial de Jalisco logo blanco.png';
import CUCEALogo from './assets/Logotipos/Colaboradores/CUCEA logo blanco.png';
import CutonalaLogo from './assets/Logotipos/Colaboradores/cutonala_logo_blanco.png';
import ENAHLogo from './assets/Logotipos/Colaboradores/ENAH logo blanco.png';
import UACHLogo from './assets/Logotipos/Colaboradores/escudo-horizontal-texto-blanco UACH_logo.png';
import FacultadDeIngenieriaLogoBlanco from './assets/Logotipos/Colaboradores/Facultad de Ingenieria Logo Blanco.png';
import FAULogo from './assets/Logotipos/Colaboradores/FAU logo grises.png';
import FEMIALogo from './assets/Logotipos/Colaboradores/FEMIA.español.blanco.png';
import GOBSayulaLogo from './assets/Logotipos/Colaboradores/GOB SAYULA LOGO BLANCO.png';
import GobiernoMunicipalChihuahuaLogo from './assets/Logotipos/Colaboradores/Gobierno Municipal Chihuahua logo blanco.png';
import IndexLogo from './assets/Logotipos/Colaboradores/Index logo blanco.png';
import Explora from './assets/Logotipos/Colaboradores/Logo blanco Explora.png';
import CUCEILogo from './assets/Logotipos/Colaboradores/Logo CUCEI blanco 2024.png';
import HackerLogo from './assets/Logotipos/Colaboradores/Logo Hacker blanco sin fondo (1).png';
import LSATLogo from './assets/Logotipos/Colaboradores/Logo LSAT blanco.png';
import FAMEXLogo from './assets/Logotipos/Colaboradores/logofamexblanco2023.png';
import MiNDIndustrialesLogo from './assets/Logotipos/Colaboradores/MiND Industriales PRO logo blanco.png';
import NASAGoddardLogo from './assets/Logotipos/Colaboradores/NASA Goddard logo balnco.png';
import RaymixFundacionLogo from './assets/Logotipos/Colaboradores/Raymix Fundacion logo blanco.png';
import SCTAEMLogo from './assets/Logotipos/Colaboradores/SCT AEM logo blanco.png';
import SemanaMundialDelEspacioLogo from './assets/Logotipos/Colaboradores/Semana Mundial del Espacio México.png';
import SIEMENSLogo from './assets/Logotipos/Colaboradores/sie-logo-white-rgb.png';
import SociedadAstronomicaChihuahuaLogo from './assets/Logotipos/Colaboradores/SOCIEDAD ASTRONÓMICA CHIHUAHUA LOGO.png';
import SpaceCenterHoustonLogo from './assets/Logotipos/Colaboradores/Space Center Houston logo blanco.png';
import SpaceGenerationAdvisoryCouncilLogo from './assets/Logotipos/Colaboradores/Space Generation Advisory Council logo blanco.png';
import ThrustersUnlimitedBlancoLogo from './assets/Logotipos/Colaboradores/Thrusters Unlimited blanco logo.png';
import TLOscurosLogo from './assets/Logotipos/Colaboradores/TL Oscuros.png';
import UDEGLogo from './assets/Logotipos/Colaboradores/UdeG logo blanco.png';
import WorldSpaceWeekLogo from './assets/Logotipos/Colaboradores/World Space Week-02.png';
//import { Home } from '@mui/icons-material';

//Campus Chief
import CampusChief01 from './assets/Audiovisual para contenido/Campus Chief/430327623_944835407433411_5062058506789620091_n.jpg';
import CampusChief02 from './assets/Audiovisual para contenido/Campus Chief/432426466_944820367434915_6220519481564049029_n.jpg';
import CampusChief03 from './assets/Audiovisual para contenido/Campus Chief/432453350_944783400771945_5881835689124556581_n.jpg';
import CampusChief04 from './assets/Audiovisual para contenido/Campus Chief/b35f2d12-490e-469a-afb6-8921b589e797.jpg';
import CampusChief05 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-2.jpg';
import CampusChief06 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-3.jpg';
import CampusChief07 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-4.jpg';
import CampusChief08 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-5.jpg';
import CampusChief09 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-6.jpg';
import CampusChief10 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-7.jpg';
import CampusChief11 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel.jpg';
import CampusChief12 from './assets/Audiovisual para contenido/Campus Chief/Copia de 20240307_133405.jpg';
import CampusChief13 from './assets/Audiovisual para contenido/Campus Chief/d4302450-fb0b-44d5-b7ed-35eb1a244dc5.jpg';
import CampusChief14 from './assets/Audiovisual para contenido/Campus Chief/Dewesoft 4.jpg';
import CampusChief15 from './assets/Audiovisual para contenido/Campus Chief/IMG_1723.HEIC';
import CampusChief16 from './assets/Audiovisual para contenido/Campus Chief/IMG_8825.HEIC';
import CampusChief17 from './assets/Audiovisual para contenido/Campus Chief/WhatsApp Image 2024-03-09 at 8.47.09 PM.jpeg';
import CampusChief18 from './assets/Audiovisual para contenido/Campus Chief/WhatsApp Image 2024-03-12 at 17.34.31 (1).jpeg';
import CampusChiefLogo from './assets/Logotipos/ENMICE/ENMICE Campus Chief logo.png';

//Store



const imageIndex = {
    //Home
    HomeCarousel1,
    HomeCarousel2,
    HomeCarousel3,
    HomeCarousel4,
    HomeCarousel5,
    HomeCarousel6,
    HomeCarousel7,
    HomeCarousel8,
    HomeCarousel9,
    HomeCarousel10,
    ENMICELogo,
    ENMICE2021Map,
    ENMICE2022Map,
    ENMICE20232024Map,

    //Staff Images
    AboutUsFelipeAvilaImage,
    AboutUsAngelVazquezImage,
    AboutUsArielGomezImage,
    AboutUsMaiteCUImage,
    AboutUsMalcomPinedaImage,
    AboutUsPaulinaAguayoImage,
    AboutUsRamonCordovaImage,
    AboutUsRodrigoMorenoImage,
    AboutUsRossyDelgadoImage,
    AboutUsXimenaVelascoImage,
    AboutUsPatyLomeliImage,
    AboutUsPaulinaCabreraImage,

    //Campus Chief Images
    AboutUsGabrielBasilioImage,
    AboutUsJoseAndresImage,
    AboutUsKarlaDiazImage,
    AboutUsLeonelImage,
    AboutUsMariaFernandaImage,
    AboutUsMtroJesusImage,
    AboutUsValeriaCurielImage,

    //ENMICE 2021
    ENMICE202101,
    ENMICE202102,
    ENMICE202103,
    ENMICE202104,
    ENMICE202105,
    ENMICE202106,
    ENMICE202107,
    ENMICE202108,
    ENMICE202109,
    ENMICE202110,
    ENMICE202111,
    ENMICE202112,
    ENMICE202113,
    ENMICE202114,
    ENMICE202115,
    ENMICE202116,

    //ENMICE 2022
    ENMICE202201,
    ENMICE202202,
    ENMICE202203,
    ENMICE202204,
    ENMICE202205,
    ENMICE202206,
    ENMICE202207,
    ENMICE202208,
    ENMICE202209,
    ENMICE202210,
    ENMICE202211,
    ENMICE202212,
    ENMICE202213,
    ENMICE202214,
    ENMICE202215,
    ENMICE202216,
    ENMICE202217,
    ENMICE202218,
    ENMICE202219,
    ENMICE202220,
    ENMICE202221,
    ENMICE202222,
    ENMICE202223,
    ENMICE202224,
    ENMICE202225,
    ENMICE202226,
    ENMICE202227,

    //ENMICE 2023
    ENMICE202301,
    ENMICE202302,
    ENMICE202303,
    ENMICE202304,
    ENMICE202305,
    ENMICE202306,
    ENMICE202307,
    ENMICE202308,
    ENMICE202309,
    ENMICE202310,
    ENMICE202311,
    ENMICE202312,
    ENMICE202313,

    //Academia
    AcademiaENMICELogo,
    AcademiaENMICEOnePager,
    AcademiaCocytenbLogo,
    AcademiaSharevoltsLogo,
    AcademiaMuseoInteractivoLogo,
    AcademiaUCEMICHLogo,
    AcademiaUniversidadTecMilenioLogo,
    AcademiaCUTLogo,
    AcademiaVikingosLogo,
    AcademiaCarousel1,
    AcademiaCarousel2,
    AcademiaCarousel3,
    AcademiaCarousel4,
    AcademiaCarousel5,
    Academia1,
    Academia2,
    Academia3,
    Academia4,
    Academia5,
    Academia6,
    Academia7,
    Academia8,
    Academia9,

    //Sponsors
    SkarchLogo,
    AnsysLogo,
    AutoTokioNissanLogo,
    AXONLogo,
    Bid360Logo,
    CraftAviaLogo,
    DaviLogo,
    GrupoSSCLogo,
    DewesoftLogo,
    ERAAviacionLogo,
    HoneywellAerospaceTechnologiesLogo,
    NoMADALogo,
    SharevoltsLogo,
    MarDSalLogo,
    RedColaLogo,
    SidralAgaLogo,
    SterenLogo,
    ThrustersUnlimitedLogo,
    UMGLogo,

    //Collaborators
    VivevolandoLogo,
    AhChihuahuaLogo,
    BuroDeConvencionesChihuahuaLogo,
    CamaraDeDiputadosLogo,
    CenaltecLogo,
    ChihuahuaCapitalLogo,
    ChihuahuaAerospaceClusterLogo,
    CIMAVLogo,
    ConsejoAeroespacialDeJaliscoLogo,
    CUCEALogo,
    CutonalaLogo,
    ENAHLogo,
    UACHLogo,
    FacultadDeIngenieriaLogoBlanco,
    FAULogo,
    FEMIALogo,
    GOBSayulaLogo,
    GobiernoMunicipalChihuahuaLogo,
    IndexLogo,
    Explora,
    CUCEILogo,
    HackerLogo,
    LSATLogo,
    FAMEXLogo,
    MiNDIndustrialesLogo,
    NASAGoddardLogo,
    RaymixFundacionLogo,
    SCTAEMLogo,
    SemanaMundialDelEspacioLogo,
    SIEMENSLogo,
    SociedadAstronomicaChihuahuaLogo,
    SpaceCenterHoustonLogo,
    SpaceGenerationAdvisoryCouncilLogo,
    TLOscurosLogo,
    ThrustersUnlimitedBlancoLogo,
    UDEGLogo,
    WorldSpaceWeekLogo,

    //Campus Chief
    CampusChief01,
    CampusChief02,
    CampusChief03,
    CampusChief04,
    CampusChief05,
    CampusChief06,
    CampusChief07,
    CampusChief08,
    CampusChief09,
    CampusChief10,
    CampusChief11,
    CampusChief12,
    CampusChief13,
    CampusChief14,
    CampusChief15,
    CampusChief16,
    CampusChief17,
    CampusChief18,
    CampusChiefLogo,

    //Store

  };
  
  export default imageIndex;